import React, { useEffect } from "react";
import useGetOrderDetails from "../hooks/account-orders/useGetOrderDetails";
import "../sass/invoice.scss";
import { formatPrice } from "../utils/money";
import { default_footer_data, default_store_info } from "../../config/footer";
import Image from "../components/common/Image";
import { isEmpty } from "../utils/functions";

const Invoice = ({ location }) => {
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get("id");
  const { isLoading, orderInfo, orderProducts, orderShippingAddress } =
    useGetOrderDetails(orderId);

  const storeInfo = process.env.GATSBY_FOOTER_INFO
    ? JSON.parse(process.env.GATSBY_FOOTER_INFO)
    : default_store_info;

  const { logo } = default_footer_data;

  useEffect(() => {}, [isLoading]);

  useEffect(() => {
    const printWithDelay = () => {
      setTimeout(() => {
        window.print();
      }, 100);
    };
    printWithDelay();
  }, []);

  return (
    <div className="order-invoice-template">
      <div className="Invoice">
        <div className="invoice-header-logo">
          <Image src={logo} alt={"header-logo"} />
        </div>
        <div className="InvoiceTitle">
          Midwest Goods Inc. Invoice for Order #{orderId}
        </div>

        <div className="invoice-store-address">
          <strong>
            1001 Foster Ave <br />
            Bensenville, Illinois 60106
            <br />
            630-912-2673
          </strong>
          <p>
            <strong>Illinois License:</strong> TP-00953
          </p>
          <p>
            <strong>California License:</strong> LDQSTF90-008520
          </p>
          <p>
            <strong>Pennsylvania License:</strong> 99007784
          </p>
          <p>
            <strong>Minnesota License:</strong> 5047756-001
          </p>
          <p>
            <strong>Washington License:</strong> 604613220
          </p>
          <p>
            <strong>Oregon License:</strong> 0001762
          </p>
          <p>
            <strong>Ohio License:</strong> 92800074
          </p>
        </div>

        <div className="AddressRow">
          <div className="AddressCol BillingAddress">
            <h3 className="invoice-heading">Bill to</h3>
            <ul className="">
              {orderInfo?.billing_address && (
                <>
                  <li>{`${orderInfo.billing_address.first_name} ${orderInfo.billing_address.last_name}`}</li>
                  <li>{orderInfo.billing_address.company}</li>
                  <li>{orderInfo.billing_address.street_1}</li>
                  <li>{orderInfo.billing_address.street_2}</li>
                  <li>{`${orderInfo.billing_address.city}, ${orderInfo.billing_address.state} ${orderInfo.billing_address.zip}`}</li>
                  <li>{orderInfo.billing_address.country}</li>
                </>
              )}
            </ul>
            <div>
              <strong>Phone No: </strong>
              <span>{orderInfo.billing_address?.phone}</span>
            </div>
            <div>
              <strong>Email: </strong>
              <span>{orderInfo.billing_address?.email}</span>
            </div>
          </div>
          <div className="AddressCol ShippingAddress">
            <h3 className="invoice-heading">Ship to</h3>
            <ul className="">
              {!isEmpty(orderShippingAddress) && (
                <>
                  <li>{`${orderShippingAddress[0].first_name} ${orderShippingAddress[0].last_name}`}</li>
                  <li>{orderShippingAddress[0].company}</li>
                  <li>{orderShippingAddress[0].street_1}</li>
                  <li>{orderShippingAddress[0].street_2}</li>
                  <li>{`${orderShippingAddress[0].city}, ${orderShippingAddress[0].state} ${orderShippingAddress[0].zip}`}</li>
                  <li>{orderShippingAddress[0].country}</li>
                </>
              )}
            </ul>
            <div>
              <strong>Phone No: </strong>
              <span>{orderShippingAddress[0]?.phone}</span>
            </div>
            <div>
              <strong>Email: </strong>
              <span>{orderShippingAddress[0]?.email}</span>
            </div>
          </div>
        </div>

        <div className="InvoiceDetails">
          <div className="InvoiceDetailsLeft">
            <div className="DetailRow">
              <div className="Label">Order:</div>
              <div className="Value">#{orderInfo.order_id}</div>
            </div>
            <div className="DetailRow">
              <div className="Label">Payment Method:</div>
              <div className="Value">
                {orderInfo.payment_method} (${orderInfo.total_ex_tax})
              </div>
            </div>
          </div>
          <div className="InvoiceDetailsRight">
            <div className="DetailRow">
              <div className="Label">Order Date:</div>
              <div className="Value">{orderInfo.order_date}</div>
            </div>
            <div className="DetailRow">
              <div className="Label">Shipping Method:</div>
              <div className="Value">
                {orderShippingAddress[0]?.shipping_method}
              </div>
            </div>
          </div>
        </div>

        <div className="InvoiceItems">
          <div className="InvoiceItems-table">
            <h2 className="invoice-heading">Order Items</h2>
            <table className="InvoiceTable">
              <thead>
                <tr>
                  <th className="ProductQuantity">Qty</th>
                  <th className="ProductSku">Code/SKU</th>
                  <th className="ProductDetails">Product Name</th>
                  <th className="ProductCost" align="right">
                    Price
                  </th>
                  <th className="ProductTotal" align="right">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody className="InvoiceItemList">
                {orderInfo?.items?.map((product, index) => (
                  <tr key={index}>
                    <td className="ProductQuantity">{product.quantity}</td>
                    <td className="ProductSku">{product.sku}</td>
                    <td className="ProductDetails">
                      {product.name}
                      <div className="ProductAttributes">
                        {product.product_options.map((option, optionIndex) => (
                          <div
                            className="ConfigurableProductRow"
                            key={optionIndex}
                          >
                            <div className="Label">{option.display_name}:</div>
                            <div className="Value">{option.display_value}</div>
                          </div>
                        ))}
                      </div>
                      <div className="ProductConfigurableFields"></div>
                    </td>
                    <td className="ProductCost">{`$${product.price_ex_tax}`}</td>
                    <td className="ProductTotal">{`$${product.total_ex_tax}`}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot className="InvoiceTotals">
                <tr className="InvoiceTotalRow">
                  <td className="total-Title" colSpan="4">
                    Subtotal
                  </td>
                  <td className="Total">
                    {formatPrice(orderInfo.sub_total_ex_tax)}
                  </td>
                </tr>

                <tr className="InvoiceTotalRow">
                  <td className="total-Title" colSpan="4">
                    Shipping
                  </td>
                  <td className="Total">
                    {formatPrice(orderInfo.shipping_cost_tax)}
                  </td>
                </tr>

                <tr className="InvoiceTotalRow">
                  <td className="total-Title" colSpan="4">
                    Store Credit
                  </td>
                  <td className="Total">
                    -{formatPrice(orderInfo.store_credit_amount)}
                  </td>
                </tr>

                <tr className="InvoiceTotalRow">
                  <td className="total-Title" colSpan="4">
                    Grand total
                  </td>
                  <td className="Total">
                    {formatPrice(orderInfo.total_inc_tax)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div className="InvoiceComments">
            <h3 className="invoice-heading">Order Comments</h3>
            <p>{orderInfo.customer_message}</p>
          </div>

          <div>
            <br />
            <br />
            <strong>Licenses &amp; Disclaimer:</strong>
            <br />
            Midwest Goods Inc. collects and remits taxes for California,
            Colorado, Connecticut, Delaware, District of Columbia, Georgia,
            Illinois, Indiana, Kansas, Kentucky, Louisiana, Maine,
            Massachusetts, Minnesota, New Hampshire, New Jersey, New Mexico,
            Ohio, Pennsylvania, Wyoming, Utah, Vermont, Virginia, Washington,
            West Virginia, Wisconsin, and Oregon. For Oregon, all taxes due
            under ORS 323.500 323.645 have been or will be paid.
            <br />
            Virginia Tobacco Products Tax Paid.
            <br />
            <br />
            Midwest Goods Inc. <strong>DOES NOT</strong> collect or remit taxes
            for any other states. The retailer is responsible for reporting,
            collecting, and remitting any applicable taxes.
            <br />
            <br />
            Please inspect and verify the contents of your shipment within 72
            Hours from the time of delivery and report any damages or
            discrepancies to Customer Service at support@midwestgoods.com or
            630-912-2673 (9AM - 7PM CST Mon-Fri, Closed Sat-Sun). Damage or
            shortage claims, or products ordered by mistake by the customer must
            be reported with in 72 Hours. Claims made after 72 Hours from time
            of delivery will be rejected. <br />
            <br />
            <strong>Note: Freight Order Deliveries</strong>
            should thoroughly be checked for any damage before you sign for your
            order. If you see any damage on / in your pallet; please add
            <strong>
              "pending- investigation of item- box arrived damaged",
            </strong>
            after your signature on the BOL. Please take a picture of your
            Signed BOL &amp; Report Midwest Sales or Customer Service Team
            within 48 Hours of Delivery.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
